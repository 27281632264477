import {
    Dialog,
    LayerHost,
    Link,
    PrimaryButton,
    ProgressIndicator,
    Stack,
    StackItem,
    Text,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { makeStyles, shorthands } from "@griffel/react";
import { sendGAEvent } from "@next/third-parties/google";
import { AdbDaemonWebUsbDeviceManager } from "@yume-chan/adb-daemon-webusb";
import { WebCodecsDecoder } from "@yume-chan/scrcpy-decoder-webcodecs";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { KeyboardEvent, useEffect, useState } from "react";
import { DemoModePanel } from "../components";
import {
    SETTING_STATE,
    STATE,
    ScrcpyCommandBar,
    VideoContainer,
} from "../components/scrcpy";
import {
    useDeviceModelQueryParam,
    useLocalStorage,
    useStreamingMode,
    useTargetDeviceLocalIpAddress,
} from "../hooks";
import { GLOBAL_STATE } from "../state";
import {
    CommonStackTokens,
    Icons,
    RouteStackProps,
    formatSpeed,
} from "../utils";

const useClasses = makeStyles({
    layerHost: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        pointerEvents: "none",
        ...shorthands.margin(0),
    },
    fullScreenContainer: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "black",
        width: "100%",
        height: "100%",
        ":focus-visible": {
            ...shorthands.outline("0"),
        },
    },
    fullScreenStatusBar: {
        display: "flex",
        color: "white",
        columnGap: "12px",
        ...shorthands.padding("8px", "20px"),
    },
    spacer: {
        flexGrow: 1,
    },
});

const ConnectingDialog = observer(() => {
    const classes = useClasses();
    const layerHostId = useId("layerHost");

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) {
        return null;
    }

    return (
        <>
            <LayerHost id={layerHostId} className={classes.layerHost} />

            <Dialog
                hidden={!STATE.connecting}
                modalProps={{ layerProps: { hostId: layerHostId } }}
                dialogContentProps={{ title: "Connecting..." }}
            >
                <Stack tokens={CommonStackTokens}>
                    <ProgressIndicator
                        label="Downloading..."
                        percentComplete={
                            STATE.serverTotalSize
                                ? STATE.serverDownloadedSize /
                                  STATE.serverTotalSize
                                : undefined
                        }
                        description={formatSpeed(
                            STATE.debouncedServerDownloadedSize,
                            STATE.serverTotalSize,
                            STATE.serverDownloadSpeed,
                        )}
                    />

                    <ProgressIndicator
                        label="Pushing..."
                        progressHidden={
                            STATE.serverTotalSize === 0 ||
                            STATE.serverDownloadedSize !== STATE.serverTotalSize
                        }
                        percentComplete={
                            STATE.serverUploadedSize / STATE.serverTotalSize
                        }
                        description={formatSpeed(
                            STATE.debouncedServerUploadedSize,
                            STATE.serverTotalSize,
                            STATE.serverUploadSpeed,
                        )}
                    />

                    <ProgressIndicator
                        label="Starting..."
                        progressHidden={
                            STATE.serverTotalSize === 0 ||
                            STATE.serverUploadedSize !== STATE.serverTotalSize
                        }
                    />
                </Stack>
            </Dialog>
        </>
    );
});

async function handleKeyEvent(e: KeyboardEvent<HTMLDivElement>) {
    if (!STATE.client) {
        return;
    }

    e.preventDefault();
    e.stopPropagation();

    const { type, code } = e;
    STATE.keyboard![type === "keydown" ? "down" : "up"](code);
}

function handleBlur() {
    if (!STATE.client) {
        return;
    }

    STATE.keyboard?.reset();
}

const FullscreenHint = observer(function FullscreenHint({
    keyboardLockEnabled,
}: {
    keyboardLockEnabled: boolean;
}) {
    const classes = useClasses();

    const [hintHidden, setHintHidden] = useLocalStorage<`${boolean}`>(
        "scrcpy-hint-hidden",
        "false",
    );

    if (!keyboardLockEnabled || !STATE.isFullScreen || hintHidden === "true") {
        return null;
    }

    return (
        <div className={classes.fullScreenStatusBar}>
            {/* <div>{GLOBAL_STATE.device?.serial}</div> */}
            {/* <div>FPS: {STATE.fps}</div> */}

            <div className={classes.spacer} />

            <div>Press and hold ESC to exit full screen</div>

            <Link onClick={() => setHintHidden("true")}>
                {`Don't show again`}
            </Link>
        </div>
    );
});

const Scrcpy: NextPage = () => {
    const classes = useClasses();
    const router = useRouter();
    const streamingMode = useStreamingMode();
    const deviceLocalIp = useTargetDeviceLocalIpAddress();
    const deviceModelQueryParam = useDeviceModelQueryParam();

    if (GLOBAL_STATE.device) {
        if (GLOBAL_STATE.device.name === "Quest 2") {
            SETTING_STATE.setDeviceModel("Quest 2");
        } else if (GLOBAL_STATE.device.name === "Quest Pro") {
            SETTING_STATE.setDeviceModel("Quest Pro");
        } else if (GLOBAL_STATE.device.name === "Quest 3") {
            SETTING_STATE.setDeviceModel("Quest 3");
        } else if (GLOBAL_STATE.device.name === "Quest 3S") {
            SETTING_STATE.setDeviceModel("Quest 3S");
        } else if (
            GLOBAL_STATE.device.name?.toLowerCase() === "pico neo3" ||
            GLOBAL_STATE.device.serial.startsWith("PA7L")
        ) {
            SETTING_STATE.setDeviceModel("Pico Neo 3"); // Neo 3, Neo 3 Eye, Neo 3 Pro
        } else if (GLOBAL_STATE.device.serial.startsWith("PA7940")) {
            SETTING_STATE.setDeviceModel("Pico G2 4K");
        } else if (GLOBAL_STATE.deviceDetails.model === "Pico G2") {
            SETTING_STATE.setDeviceModel("Pico G2");
        } else if (GLOBAL_STATE.device.name === "PICO 4 Enterprise") {
            SETTING_STATE.setDeviceModel("Pico 4");
        } else if (GLOBAL_STATE.device.name === "PICO G3") {
            SETTING_STATE.setDeviceModel("Pico G3");
        } else if (GLOBAL_STATE.deviceDetails.model === "VIVE Focus 3") {
            SETTING_STATE.setDeviceModel("VIVE Focus 3");
        } else if (GLOBAL_STATE.deviceDetails.model === "VIVE Focus Vision") {
            SETTING_STATE.setDeviceModel("VIVE Focus Vision");
        } else {
            SETTING_STATE.setDeviceModel("default");
        }

        SETTING_STATE.syncQuality(streamingMode);
    }

    useEffect(() => {
        SETTING_STATE.setDeviceModel(deviceModelQueryParam);
        SETTING_STATE.syncQuality(streamingMode);
    }, [deviceModelQueryParam]);

    useEffect(() => {
        if (streamingMode === "WIRED" || !deviceLocalIp) return;
        STATE.directStartWs(deviceLocalIp);
        STATE.running = true;
    }, [streamingMode, deviceLocalIp]);

    useEffect(() => {
        // Detect WebCodecs support at client side
        if (
            SETTING_STATE.decoders.length === 1 &&
            WebCodecsDecoder.isSupported()
        ) {
            runInAction(() => {
                SETTING_STATE.decoders.unshift({
                    key: "webcodecs",
                    name: "WebCodecs",
                    Constructor: WebCodecsDecoder,
                });
            });
        }
    }, []);

    const [keyboardLockEnabled, setKeyboardLockEnabled] = useState(false);
    useEffect(() => {
        if (!("keyboard" in navigator)) {
            return;
        }

        // Keyboard Lock is only effective in fullscreen mode,
        // but the `lock` method can be called at any time.

        // @ts-expect-error
        navigator.keyboard.lock();
        setKeyboardLockEnabled(true);

        return () => {
            // @ts-expect-error
            navigator.keyboard.unlock();
        };
    }, []);

    useEffect(() => {
        window.addEventListener("blur", handleBlur);

        return () => {
            window.removeEventListener("blur", handleBlur);
        };
    }, []);

    const supported = !!AdbDaemonWebUsbDeviceManager.BROWSER;

    if (!router.isReady) return <div>Loading...</div>;
    return (
        <Stack {...RouteStackProps}>
            <Head>
                <title>ManageXR USB Screencasting Tool</title>
            </Head>

            {STATE.running || streamingMode === "WIRELESS" ? (
                <>
                    <ScrcpyCommandBar wireless={streamingMode === "WIRELESS"} />
                    <Stack horizontal grow styles={{ root: { height: 0 } }}>
                        <div
                            ref={STATE.setFullScreenContainer}
                            className={classes.fullScreenContainer}
                            tabIndex={0}
                            onKeyDown={handleKeyEvent}
                            onKeyUp={handleKeyEvent}
                        >
                            <FullscreenHint
                                keyboardLockEnabled={keyboardLockEnabled}
                            />

                            {/* <DeviceView
                                width={STATE.rotatedWidth}
                                height={STATE.rotatedHeight}
                                // BottomElement={NavigationBar}
                            > */}
                            <VideoContainer />
                            {/* </DeviceView> */}
                        </div>

                        <div
                            style={{
                                padding: 12,
                                overflow: "hidden auto",
                                display: STATE.logVisible ? "block" : "none",
                                width: 500,
                                fontFamily: "monospace",
                                overflowY: "auto",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                            }}
                        >
                            {STATE.log.map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </div>

                        <DemoModePanel
                            style={{
                                display: STATE.demoModeVisible
                                    ? "block"
                                    : "none",
                            }}
                        />
                    </Stack>
                </>
            ) : (
                <>
                    <p style={{ maxWidth: "60em" }}>
                        Welcome to the ManageXR USB Screencasting Tool! This web
                        app allows you to cast your device's screen to a browser
                        window by connecting the device to your computer via a
                        USB cable.
                    </p>
                    <p>
                        To get started:
                        <ol>
                            <li>
                                {supported && "✅ "}Make sure you're using
                                Google Chrome, Microsoft Edge, or other
                                Chromium-based browser.
                            </li>
                            <li>
                                {GLOBAL_STATE.deviceList.length > 0 && "✅ "}
                                Connect your device to your computer via USB
                                cable.
                            </li>
                            <li>
                                {GLOBAL_STATE.deviceList.length > 0 && "✅ "}
                                Click the blue "Add" button in the left nav.
                            </li>
                            <li>
                                {GLOBAL_STATE.deviceList.length > 0 && "✅ "}
                                Find your device in the popup and click
                                "Connect".
                                <ul>
                                    <li>
                                        Troubleshooting: Not finding your
                                        device? Make sure it has developer mode
                                        enabled.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                {GLOBAL_STATE.adb && "✅ "}Click the blue
                                "Connect" button in the left nav. Follow any
                                instructions that may appear during this
                                process.
                            </li>
                            <li>
                                Click one of the casting options below (wired or
                                wireless)
                            </li>
                        </ol>
                    </p>
                    {/* <div>
                        <ExternalLink
                            href="https://github.com/Genymobile/scrcpy"
                            spaceAfter
                        >
                            Scrcpy
                        </ExternalLink>
                        can mirror device display and audio with low latency and
                        control the device, all without root access.
                    </div>
                    <div>
                        This is a TypeScript re-implementation of the client
                        part. Paired with official pre-built server binary.
                    </div> */}
                    <Stack horizontal>
                        <StackItem
                            align="start"
                            style={{
                                maxWidth: 300,
                                padding: 8,
                                border: "1px solid #ddd",
                            }}
                        >
                            <Stack>
                                <span style={{ marginBottom: 8 }}>
                                    <b>Wireless Casting</b> requires your
                                    computer and device to be on the same Wi-Fi
                                    network. You can unplug your USB after
                                    starting.
                                </span>
                                <PrimaryButton
                                    text={
                                        GLOBAL_STATE.adb &&
                                        !SETTING_STATE.deviceModel.includes(
                                            "Quest",
                                        )
                                            ? "Currently only available on Quest"
                                            : "Start Wireless Casting"
                                    }
                                    disabled={
                                        !GLOBAL_STATE.adb ||
                                        !SETTING_STATE.deviceModel.includes(
                                            "Quest",
                                        )
                                    }
                                    onClick={() => {
                                        sendGAEvent(
                                            "event",
                                            "click_start_wireless_casting_button",
                                            {
                                                serial: GLOBAL_STATE.device
                                                    ?.serial,
                                                name: GLOBAL_STATE.device?.name,
                                            },
                                        );
                                        STATE.startWsSeparateWindow();
                                    }}
                                    iconProps={
                                        GLOBAL_STATE.adb &&
                                        !SETTING_STATE.deviceModel.includes(
                                            "Quest",
                                        )
                                            ? {}
                                            : {
                                                  iconName: Icons.Wifi,
                                                  style: {
                                                      height: 30,
                                                      width: 30,
                                                      fontSize: 30,
                                                  },
                                              }
                                    }
                                />
                            </Stack>
                        </StackItem>
                        <StackItem
                            align="start"
                            style={{
                                display: "flex",
                                alignSelf: "center",
                                margin: "0 0.5em",
                            }}
                        >
                            <Text variant="large">- or -</Text>
                        </StackItem>
                        <StackItem
                            align="start"
                            style={{
                                maxWidth: 300,
                                padding: 8,
                                border: "1px solid #ddd",
                            }}
                        >
                            <Stack>
                                <span style={{ marginBottom: 8 }}>
                                    <b>Wired Casting</b> has better quality and
                                    lower latency, but requires a USB cable to
                                    remain connected while casting.
                                </span>
                                <PrimaryButton
                                    text="Start Wired Casting"
                                    disabled={!GLOBAL_STATE.adb}
                                    onClick={() => {
                                        sendGAEvent(
                                            "event",
                                            "click_start_wired_casting_button",
                                            {
                                                serial: GLOBAL_STATE.device
                                                    ?.serial,
                                                name: GLOBAL_STATE.device?.name,
                                            },
                                        );
                                        STATE.start();
                                    }}
                                    iconProps={{
                                        iconName: Icons.ConnectorRegular,
                                        style: {
                                            height: 30,
                                            width: 30,
                                            fontSize: 30,
                                        },
                                    }}
                                />
                            </Stack>
                        </StackItem>
                    </Stack>
                    <br></br>
                    <br></br>
                    <p>
                        Note: This is a beta browser-based USB casting solution,
                        expect bugs! 🐛 Please send feedback to
                        support@managexr.com
                    </p>
                    <p style={{ maxWidth: "60em" }}>
                        Troubleshooting tips:
                        <ul>
                            <li>
                                Make sure you're using Google Chrome, Microsoft
                                Edge, or other Chromium-based browser.
                            </li>
                            <li>
                                Make sure your device has developer mode (aka
                                USB Debugging) enabled.
                            </li>
                            <li>
                                This app won't work while other programs on your
                                computer are communicating with your VR device.
                                Close programs like the ManageXR Device Setup
                                Tool, Oculus Developer Hub, SideQuest, Android
                                File Transfer, and Android Studio. If you're a
                                developer and use ADB directly, you must also
                                kill your ADB server by running this terminal
                                command: `adb kill-server`.
                            </li>
                            <li>
                                Make sure you disable your Ad Blocker on this
                                site. If clicking the Wireless Casting button
                                makes the screen flicker but then nothing
                                happens, it is most likely your Ad Blocker
                                preventing our wireless streaming popup window.
                            </li>
                            <li>
                                Make sure the cable you're using to connect your
                                device to your computer is able to transmit data
                                and isn't a simple charging cable.
                            </li>
                            <li>
                                If your Quest screen ever goes to black
                                mid-stream, it is most likely because a system
                                notification has appeared, which temporarily
                                blocks screen streaming. Just wait a few seconds
                                and the stream will return.
                            </li>
                        </ul>
                    </p>
                    {/* {SETTING_DEFINITIONS.get().map((definition) => (
                        <SettingItem
                            key={definition.key}
                            definition={definition}
                            value={
                                (SETTING_STATE[definition.group] as any)[
                                    definition.key
                                ]
                            }
                            onChange={action(
                                (definition, value) =>
                                    ((SETTING_STATE[definition.group] as any)[
                                        definition.key
                                    ] = value),
                            )}
                        />
                    ))} */}
                    <ConnectingDialog />
                </>
            )}
        </Stack>
    );
};

export default observer(Scrcpy);
